import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal, Textarea } from '@cognitiv/cassiopeia-ui';
import copy from 'copy-to-clipboard';
import { selectModal } from 'ducks/modals/selectors';
import { updateModal } from 'ducks/modals/slices';
import { updateSnackbar } from 'ducks/overlays/slices';
import { parameter_default } from 'products/taurus/components/parameters/constants';
import { TAURUS_MODALS } from 'products/taurus/modals/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const ManageTaurusParameterValue = () => {
  const dispatch = useAppDispatch();

  const { is_open, parameter, read_only, onSubmit } = useAppSelector((state) => selectModal(state, TAURUS_MODALS.MANAGE_TAURUS_PARAMETER_VALUE));

  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(parameter.parameter_value || '');
  }, [parameter]);

  const onClose = useCallback(() => {
    dispatch(
      updateModal({
        [TAURUS_MODALS.MANAGE_TAURUS_PARAMETER_VALUE]: {
          is_open: false,
          parameter: { ...parameter_default },
          read_only: false,
          onSubmit: () => {},
        },
      }),
    );
  }, [dispatch]);

  const onCopy = useCallback(() => {
    copy(value);
    dispatch(updateSnackbar({ title: 'Press Ctrl + V to paste the Parameter Value.' }));
  }, [value, dispatch]);

  const onClick = useCallback(() => {
    onSubmit({ ...parameter, parameter_value: value });
    onClose();
  }, [value, onSubmit, parameter, onClose]);

  return (
    <Modal identifier={TAURUS_MODALS.MANAGE_TAURUS_PARAMETER_VALUE} is_open={is_open} width={600} onClose={onClose}>
      <Modal.Header>{parameter.parameter_name || ''}</Modal.Header>
      <Modal.Body>
        <Textarea value={value} onChange={({ value }) => setValue(value)} disabled={read_only} height={400} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="subtle" onClick={onCopy}>
          Copy
        </Button>
        <Button onClick={onClick} disabled={read_only}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
